export interface Timeouts {
  [key: string]: any;
}

export interface Breakpoint {
  width: number;
  name: string;
}

export class Responsive {

  public static timeouts: Timeouts = {};

  // Order of values is important (small to big)
  public static maxBreakpoints: Array<Breakpoint> = [];
  // Order of values is important (big to small)
  public static minBreakpoints: Array<Breakpoint> = [];

  public static windowWidth: number;
  public static breakpoints:any;

  public static init(breakpoints: any) {
    Responsive.breakpoints = breakpoints;
    Responsive.setBreakpoints(breakpoints);
    Responsive.windowWidth = Responsive.windowsWidth();
    Responsive.event();
  }

  private static setBreakpoints(breakpoints: any) {
    let maxBreakpoints: Array<Breakpoint> = [];
    let minBreakpoints: Array<Breakpoint> = [];

    $.each(breakpoints, function (index: string, value: string) {
      let name = index;
      let width = parseInt(value.replace('px', ''));

      if (name.match('max')) {
        maxBreakpoints.push({width: width, name: name});
      }
      if (name.match('min')) {
        minBreakpoints.push({width: width, name: name});
      }
    });
    Responsive.maxBreakpoints = maxBreakpoints.sort((a, b) => a.width - b.width);
    Responsive.minBreakpoints = minBreakpoints.sort((a, b) => b.width - a.width);

  }

  public static windowsWidth(): number {
    for (let i = 0; i <= 5120; i++) {
      if (window.matchMedia("(max-width: " + i + "px)").matches) {
        return i;
      }
    }
    return 5120;
  }

  public static getMaxBreakpoint(): Array<string> {
    let maxArray: Array<string> = [];

    for (const breakpoint of Responsive.maxBreakpoints) {
      if (window.matchMedia("(max-width: " + breakpoint.width + "px)").matches) {
        maxArray.push(breakpoint.name);
      }
    }

    return maxArray;
  }

  public static getMinBreakpoint(): Array<string> {
    let minArray: Array<string> = [];

    for (const breakpoint of Responsive.minBreakpoints) {
      if (window.matchMedia("(min-width: " + breakpoint.width + "px)").matches) {
        minArray.push(breakpoint.name);
      }
    }

    return minArray;
  }

  public static getBreakpoint(): any {
    return {
      max: Responsive.getMaxBreakpoint(),
      min: Responsive.getMinBreakpoint(),
      windowWidth: Responsive.windowWidth
    };
  }

  public static isMax(breakpoint: string): boolean {
    Responsive.breakpointExist(breakpoint);
    return Responsive.getBreakpoint().max.includes(breakpoint);
  }

  public static isMin(breakpoint: string): boolean {
    Responsive.breakpointExist(breakpoint);
    return Responsive.getBreakpoint().min.includes(breakpoint);
  }

  public static breakpointExist(breakpoint: string) {
    let breakpoints = Responsive.maxBreakpoints.concat(Responsive.minBreakpoints);
    for(const breakpointAvailable of breakpoints){
      if(breakpointAvailable.name === breakpoint){
        return true;
      }
    }
    console.warn('Le breakpoint '+ breakpoint+ ' n\'est pas valide.');
    return false;

  }

  public static onWindowResize(responsiveFunction: Function, timeout: number = 100) {

    function guidGenerator() {
      let S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      };
      return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }

    let id = guidGenerator();

    Responsive.timeouts[id] = setTimeout(function () {
    }, 0);

    $(window).on('resize', function () {
      clearTimeout(Responsive.timeouts[id]);
      Responsive.timeouts[id] = setTimeout(function () {
        responsiveFunction();
      }, timeout);

    });
  }

  private static event() {
    $(window).on('resize', () => {
      Responsive.windowWidth = Responsive.windowsWidth()
    })
  }

  public static getBreakpointKey(value:string){
    return Object.keys(Responsive.breakpoints).find(key => Responsive.breakpoints[key] === value);
  }

}