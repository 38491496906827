import {TweenMax, Power2} from 'gsap';

export class ScrollTop {

  public static element: HTMLElement;
  public static $element: JQuery<HTMLElement>;
  public static visible: boolean;

  public static init() {

    ScrollTop.$element = $('.Scroll-top');
    ScrollTop.element = ScrollTop.$element.get(0);
    ScrollTop.visible = false;

    ScrollTop.events();
  }


  public static events() {

    $(window).on('scroll', function () {
      if (!$('.Introduction').length) {
        if ($(window).scrollTop() >= $('.Header').height() && ScrollTop.visible === false) {
          ScrollTop.showScrollTop();
        } else if ($(window).scrollTop() < $('.Header').height() && ScrollTop.visible === true) {
          ScrollTop.hideScrollTop();
        }
      }
    });

    ScrollTop.$element.on('click', function () {
      $('html, body').animate({
        scrollTop: 0
      }, 500);
      return false;
    });

  }

  public static showScrollTop() {
    ScrollTop.visible = true;
    ScrollTop.$element.css("opacity", 1);
  }

  public static hideScrollTop() {
    ScrollTop.visible = false;
    ScrollTop.$element.css("opacity", 0);
  }


}