declare global {
  interface Window {
    dataLayer: DataLayer;
  }
}

export interface DataLayer {
  push<T extends Function>(event: DLEvent): any;
}

type DLEvent = Record<string, any>;

export class GTM {

  public static add(event: DLEvent) {
    if (!GTM.dataLayerActive()) {
      return;
    }
    console.log('GTM.add', event);
    window.dataLayer.push(event);
  }

  private static dataLayerActive(): boolean {
    return Array.isArray(window.dataLayer);
  }
}
