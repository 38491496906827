import 'babel-polyfill';
import 'Front/style.scss';
import {Responsive} from './ts/responsive/responsive';
import {ScrollTop} from "./modules/scroll-top/scroll-top";

// @ts-ignore
import theme from 'Front/scss/abstract/_variables.scss.js';
import 'Front/ts/jqueryExtend';

// @ts-ignore
import {TimelineMax, TweenMax, Power4, Power0, Power1, Power2, Bounce, ScrollToPlugin} from 'gsap/all';

const plugins = [ScrollToPlugin];

// @ts-ignore
import ScrollMagic from 'scrollmagic/scrollmagic/uncompressed/ScrollMagic.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';

import {debounce} from 'lodash';
import imagesLoaded from 'imagesloaded';
import {GTM} from "./ts/utils/gtm";

declare global {
  interface Window { dataLayer: DataLayer; }
}
interface DataLayer {
  push<T extends Function>(data: any): any;
}


$(function () {

  Responsive.init(theme.breakpoints);

  let $intro = $('.Intro');
  let $introBe = $intro.find('.Intro-logo img:first-child');
  let $introOne = $introBe.next();
  let $introTrait = $intro.find('.Intro-trait');
  let $introText = $intro.find('.Intro-text');
  let $introBaseline = $intro.find('.Intro-baseline');
  let $introSlider = $intro.find('.Intro-slider');
  let $introVideo = $intro.find('.Intro-video');

  let introAnimation = new TimelineMax({delay: 1});
  introAnimation.to($introTrait, 2, {width: 328, ease: Power4.easeOut})
    .from($introBe, 2, {y: -1000, autoAlpha: 0, ease: Power4.easeOut}, 1)
    .from($introOne, 2, {y: 1000, autoAlpha: 0, ease: Power4.easeOut}, 1)
    .staggerFrom([$introBaseline, $introVideo, $introText, $introSlider], 2, {autoAlpha: 0}, 0.7, 2);


  function update() {
    $('.Beone [data-id]').each(function (index, value) {
      let $this = $(this);
      let $titleWrapper = $this.find('> div');
      let id = $this.data('id');
      let $clone = $('.Images [data-id=' + id + ']');
      let $parentClone = $clone.parents('.Images-wrapper');

      TweenMax.set($clone, {
        // @ts-ignore
        top: $titleWrapper.box().offsetTop - $parentClone.box().offsetTop,
        // @ts-ignore
        left: $titleWrapper.box().offsetLeft - $parentClone.box().offsetLeft,
        width: Math.ceil($this.outerWidth()),
        height: Math.ceil($this.outerHeight())
      });
    });
  }

  function resetScrollMagic() {
    if (controller) {
      controller.destroy(true);
      controller = new ScrollMagic.Controller({});
      createScenes();
    }
  }

  function createScenes() {

    let $sectionsImages = $('.Section-images');

    TweenMax.set([$('.Beone'), $('.Beone-title'), $('.Beone-trait'), $('.Beone-title > div'), $('.Images-wrapper')], {clearProps: 'all'});
    update();

    $sectionsImages.each(function (index, value) {
      let $this = $(value);

      let $beoneTrigger = $this.find('.Beone-trigger');
      let $beone = $this.find('.Beone');
      let $beoneTrait = $this.find('.Beone-trait');
      let $beoneTitle = $this.find('.Beone-title');
      let $images = $this.find('.Images');
      let $beOneTitleLeft = $this.find('.Beone-title.left > div');
      let $beOneTitleRight = $this.find('.Beone-title.right > div');

      let $firstImage = $this.find('.Images-wrapper').eq(0);
      let $allImages = $this.find('img');

      let distance = $this.outerHeight() - $beone.outerHeight() - 40;

      // Prepare animations
      let tween = new TimelineMax({
        onComplete: function () {
          TweenMax.set([$beoneTrait, $beoneTitle], {clearProps: 'transform, opacity, visibility'});
        }
      });
      tween.from([$beoneTrait, $beoneTitle], .5, {autoAlpha: 0}, 0);
      tween.from($beone.find($beOneTitleLeft), .5, {y: 400}, 0);
      tween.from($beone.find($beOneTitleRight), .5, {y: -400}, 0);


      let tween2 = new TimelineMax();
      tween2.to([$beoneTrait, $beoneTitle], 5, {y: distance, ease: Power0.easeNone}, 0);

      let tween3 = new TimelineMax();
      tween3.from($firstImage, 2, {y: -200, ease: Power1.easeOut}, 0);


      imageScenes.push(new ScrollMagic.Scene({
        triggerElement: $beoneTrigger.get(0),
        duration: $beone.outerHeight()
      })
        .setTween(tween)
        //.addIndicators()
        .addTo(controller));

      imageScenes.push(new ScrollMagic.Scene({
        triggerElement: $beoneTrigger.get(0),
        offset: $beone.outerHeight(),
        duration: distance
      })
        .setTween(tween2)
        //.addIndicators()
        .addTo(controller));

      imageScenes.push(new ScrollMagic.Scene({
        triggerElement: $images.get(0),
        offset: 0,
        duration: 500
      })
        .setTween(tween3)
        //.addIndicators()
        .addTo(controller));
    });


    /* FlyingCards */
    let flyingCardsTween = new TimelineMax();
    flyingCardsTween.staggerTo('.FlyingCards-item', 2, {y: -200, ease: Power1.easeInOut}, .25);

    let flyingCardsScene = new ScrollMagic.Scene({
      triggerElement: '#FlyingCards-1',
      offset: -100,
      duration: $('#FlyingCards-1').outerHeight() * 1.5
    })
      .setTween(flyingCardsTween)
      //.addIndicators()
      //.addTo(controller); // Désactivée


    /* Logos */
    let logosTween = new TimelineMax();
    logosTween.staggerTo('.Logos-item', 2, {y: -200, ease: Power1.easeInOut}, .5);

    let logoScene = new ScrollMagic.Scene({
      triggerElement: '#Logos-1',
      offset: -300,
      duration: $('#Logos-1').outerHeight() * 1.5
    })
      .setTween(logosTween)
      //.addIndicators()
      .addTo(controller);

    /* Header */
    let headerTween = new TimelineMax();
    headerTween.to('.Header', 2, {autoAlpha: 1});

    let headerScene = new ScrollMagic.Scene({
      triggerElement: '#start',
      duration: 100
    })
      .setTween(headerTween)
      //.addIndicators()
      .addTo(controller);


    /* Hash Scenes */
    let $intoduction = $('#introduction');
    let hashScene1 = new ScrollMagic.Scene({
      triggerElement: '#introduction',
      duration: $intoduction.outerHeight()
    })
      .on('start end', function (e: any) {
        if (e.state) {
          TweenMax.set('[data-goto]', {className: '-=active'});
          TweenMax.set('[data-goto="#introduction"]', {className: '+=active'});
        }
      })
      //.addIndicators()
      .addTo(controller);

    let $pourquoi = $('#pourquoi');
    let hashScene2 = new ScrollMagic.Scene({
      triggerElement: '#pourquoi',
      duration: $pourquoi.outerHeight()
    })
      .on('start end', function (e: any) {
        if (e.state) {
          TweenMax.set('[data-goto]', {className: '-=active'});
          TweenMax.set('[data-goto="#pourquoi"]', {className: '+=active'});
        }
      })
      //.addIndicators()
      .addTo(controller);

    let $fonctionnalites = $('#fonctionnalites');
    let hashScene3 = new ScrollMagic.Scene({
      triggerElement: '#fonctionnalites',
      duration: $fonctionnalites.outerHeight()
    })
      .on('start end', function (e: any) {
        if (e.state) {
          TweenMax.set('[data-goto]', {className: '-=active'});
          TweenMax.set('[data-goto="#fonctionnalites"]', {className: '+=active'});
        }
      })
      //.addIndicators()
      .addTo(controller);

    let $quisommesnous = $('#quisommesnous');
    let hashScene4 = new ScrollMagic.Scene({
      triggerElement: '#quisommesnous',
      duration: $quisommesnous.outerHeight()
    })
      .on('start end', function (e: any) {
        if (e.state) {
          TweenMax.set('[data-goto]', {className: '-=active'});
          TweenMax.set('[data-goto="#quisommesnous"]', {className: '+=active'});
        }
      })
      //.addIndicators()
      .addTo(controller);


    let $contact = $('#contact');
    let hashScene5 = new ScrollMagic.Scene({
      triggerElement: '#contact',
      duration: $contact.outerHeight()
    })
      .on('start end', function (e: any) {
        if (e.state) {
          TweenMax.set('[data-goto]', {className: '-=active'});
          TweenMax.set('[data-goto="#contact"]', {className: '+=active'});
        }
      })
      //.addIndicators()
      .addTo(controller);
  }


  let controller: any = new ScrollMagic.Controller({});
  let imageScenes: any = [];
  createScenes();
  $(window).on('resize', debounce(resetScrollMagic, 200));

  let imagesLoad = imagesLoaded(document.querySelector('body'), function () {
    resetScrollMagic();
  });


  // GOTO
  $('[data-goto]').on('click', function () {
    let $this = $(this);
    let hash = $this.data('goto');
    let $hash = $(hash);

    if ($hash.length > 0) {
      TweenMax.to(window, 1.5, {scrollTo: hash, ease: Power4.easeOut});
    }
  });


  // Cartes
  let $cartes = $('.Cartes-item');
  $cartes.data('opened', false);
  $cartes.on('click', function () {
    let $this = $(this);
    let $popup = $this.find('.Cartes-popup');
    let opened = $this.data('opened');

    let $otherPopup = $('.Cartes-popup').not($popup);
    TweenMax.to($otherPopup, 0.2, {autoAlpha: 0, scale: 0.8});
    $otherPopup.parent('.Cartes-item').data('opened', false);

    if (opened) {
      console.log('CLOSE');
      $this.data('opened', false);
      TweenMax.to($popup, 0.2, {autoAlpha: 0, scale: 0.8});
    } else {
      console.log('OPEN');
      $this.data('opened', true);
      TweenMax.to($popup, 0.2, {autoAlpha: 1, scale: 1});
    }
  });


  // Forms
  function checkIfEmpty() {
    let $this = $(this);

    if ($this.val() === '') {
      TweenMax.to($this, 0.5, {borderColor: theme.colors.colorGreyLight, color: theme.colors.colorGreyLight})
    } else {
      TweenMax.to($this, 0.5, {borderColor: theme.colors.colorBlue, color: theme.colors.colorBlue});
      $this.removeClass('error');
    }
  }

  let $input = $('.Contact').find('input, textarea').not('[type=hidden]').not('#sendToFax');
  let $message = $('.Contact-message');

  $input.on('input click blur focus', checkIfEmpty);
  $message.on('click', function () {
    TweenMax.to($message, 0.2, {autoAlpha: 0});
  });

  $('#contactform').on('submit', function (e) {
    e.preventDefault();
    let $this = $(this);
    let errors = false;

    $input.each(function (index, value) {
      let $value = $(value);
      if ($value.val() === '') {
        let error = $value.data('error');
        $value.attr('placeholder', error).addClass('error');
        errors = true;
      } else {
        let placeholder = $value.data('placeholder');
        $value.attr('placeholder', placeholder).removeClass('error');
      }
    });

    // Si erreurs
    if (errors) {

    } else {

      const lang = $("html").attr("lang");
      window.dataLayer.push({'event': 'mailSended', lang});

      $.post($this.attr('action'), $this.serialize()).done(function (data) {
        TweenMax.to($message, 0.2, {autoAlpha: 0});

        if (data.success) {
          TweenMax.to($message.filter('.success'), 0.2, {autoAlpha: 1});
          const email = $this.serializeArray()[4].value;
          const phone = $this.serializeArray()[5].value;
          GTM.add({'event': 'contact', 'email': email, 'phone_number': phone});
        } else {
          TweenMax.to($message.filter('.error'), 0.2, {autoAlpha: 1});
        }
      })
    }


  });


  $('#newsletter').on('submit', function (e) {
    e.preventDefault();
    let $this = $(this);
    let errors = false;

    let $button = $this.find('button');

    $button.attr('disabled', 'disabled');
    let error = {
      success: $this.data('success'),
      error: $this.data('error'),
      required: $this.data('required')
    };


    if ($this.find('input[name="email"]').val() === '') {
      $('.Footer-inputError').text(error.required);
      setTimeout(function () {
        $button.removeAttr('disabled');
      }, 1000);
    } else {

      $.post($this.attr('action'), $this.serialize()).done(function (data) {
        if (data.success) {
          $('.Footer-inputError').text(error.success);
          setTimeout(function () {
            $button.removeAttr('disabled');
          }, 1000);
          const emailValue = $this.serializeArray()[0].value
          GTM.add({'event': 'newsletter', 'email': emailValue});
        } else {
          $('.Footer-inputError').text(error.error);
          setTimeout(function () {
            $button.removeAttr('disabled');
          }, 1000);
        }
      });


    }
  });


  /* Popup */
  let $popup = $('.Popup');

  $('.Intro-video').on('click', function(e){
    e.preventDefault();
    TweenMax.to($popup, .5, { autoAlpha: 1 });

    if(typeof window.player !=='undefined'){
      // @ts-ignore
      let player = window.player;
      player.playVideo();
    }

  });

  $('.Popup-close').on('click', function(e){
    TweenMax.to($popup, .5, { autoAlpha: 0 });

    if(typeof window.player !=='undefined'){
      // @ts-ignore
      let player = window.player;
      player.stopVideo();
    }
  });


  /* Scroll Animation */
  let scrollAnimation = new TimelineMax({ repeat: -1 });
  let $scroll = $('.Scroll');
  scrollAnimation.to($scroll, .5, { y: '-50%', delay: 1 });
  scrollAnimation.to($scroll, 1, { y: '0%', ease: Bounce.easeOut });


  /* Scroll to Top */
  ScrollTop.init();


  /* INTRO VIDEO */

  function scrollbarWidth() {

    let outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

    document.body.appendChild(outer);

    let widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    let inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    let widthWithScroll = inner.offsetWidth;

    // remove divs
    if(outer.parentNode !== null) {
      outer.parentNode.removeChild(outer);
    }

    return widthNoScroll - widthWithScroll;

  }

  let $modalPrimaryLayer = $(".ModalVideo-layout");
  const $vid = $("#VisaChipCardVideo");
  const vid = $vid.get(0);

  $('.Intro-videoFonctionnalites').on('click', function () {
    showModal();
  });

  $modalPrimaryLayer.find('div').on('click', function (e) {
    e.stopPropagation();
  });

  $modalPrimaryLayer.on('click', function () {
    hideModal();
  });

  $modalPrimaryLayer.find('.ModalVideo-close').on('click', function () {
    hideModal();
  });

  function showModal() {
    $('body').css({"overflow-y": "hidden", "padding-right": scrollbarWidth()});
    $modalPrimaryLayer.css("display", "flex");
    TweenMax.to($modalPrimaryLayer, .5, {autoAlpha: 1});
    // @ts-ignore
    vid.play();
  }

  function hideModal() {
    TweenMax.to($modalPrimaryLayer, .5, {autoAlpha: 0, onComplete: function() {
        $('body').css({"overflow-y": "visible", "padding-right": 0});
        $modalPrimaryLayer.css("display", "none");
      }});
    // @ts-ignore
    vid.pause();
  }

  $(window).on('load', function() {
    showModal();
  });


});
