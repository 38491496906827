interface JQuery<TElement = HTMLElement> {
  box(): Box;
}

interface Box {
  left: number;
  right: number;
  top: number;
  bottom: number;
  x: number;
  y: number;
  width: number;
  height: number;
  offsetTop: number;
  offsetLeft: number;
}

jQuery.fn.extend({
  box: function () {
    let thisElement = this.get(0);
    let box = thisElement.getBoundingClientRect();
    let $this = $(thisElement);
    box.offsetLeft = $this.offset().left;
    box.offsetTop = $this.offset().top;

    return box;
  }
});