module.exports = {
  breakpoints: {
    maxMobile: '1199px',
    minDesktop: '1200px'
  },
  fonts: {
    fontOswald: "'Quicksand', Helvetica, Arial, sans-serif",
    bold: 700,
    medium: 500,
    regular: 400,
    light: 300
  },
  colors: {
    colorBlue: '#5fc6f0',
    colorGrey: '#3c3c3b',
    colorPurple: '#472a7d',
    colorGreyLight: '#cccccc'
  }
};